import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class RmdFloatingMenuService {
  public isOpen$ = new BehaviorSubject<boolean>(false)

  public open(): void {
    this.isOpen$.next(true)
  }

  public close(): void {
    this.isOpen$.next(false)
  }
}
