import { Injectable, OnDestroy } from '@angular/core'
import { UntypedFormControl } from '@angular/forms'
import { Router } from '@angular/router'
import { select, Store } from '@ngrx/store'
import { Subject } from 'rxjs'
import { filter, takeUntil, distinctUntilChanged } from 'rxjs/operators'

import { AppState } from '../../../_core/root-store/root-reducers'
import { ServiceLocation } from '../../models/customer.models'
import { userActions } from '../../../_core/root-store/user/user.actions'
import {
  selectSelectedServiceLocation,
  selectServiceLocations,
} from '../../../_core/root-store/user/user.selectors'
import { RmdFloatingMenuService } from '../rmd-floating-menu/rmd-floating-menu.service'
import { isEqual } from 'lodash'
import { QueryParamsService } from '../../../_core/services/query-params.service'

@Injectable()
export class RmdSitesSelectService implements OnDestroy {
  public isInitialSelection = true
  private _destroy = new Subject<void>()

  public serviceLocations: ServiceLocation[]
  public selectedServiceLocation = new UntypedFormControl('')

  constructor(
    private _store: Store<AppState>,
    private _router: Router,
    private floatingMenuService: RmdFloatingMenuService,
    private queryParamsService: QueryParamsService
  ) {
    this.selectedServiceLocation.valueChanges
      .pipe(takeUntil(this._destroy))
      .subscribe((serviceLocation) => {
        this.queryParamsService.remove(['quarter', 'year', 'subAccountId'])

        this._store.dispatch(
          userActions.SELECT_FLEET_FROM_DROPDOWN(
            serviceLocation.fleetAccountId,
            serviceLocation.fleetCompanyId,
            null,
            this.isInitialSelection
          )
        )

        this.floatingMenuService.close()
      })

    this._store
      .pipe(
        select(selectSelectedServiceLocation),
        filter((serviceLocation) => !!serviceLocation),
        distinctUntilChanged(isEqual)
      )
      .subscribe((serviceLocation) => {
        this.isInitialSelection = false
        this._handleServiceLocationChange(serviceLocation)
      })

    this._store
      .select(selectServiceLocations)
      .pipe(filter((serviceLocations) => serviceLocations !== null))
      .subscribe((serviceLocations) => {
        this.serviceLocations = serviceLocations
      })
  }

  ngOnDestroy(): void {
    this._destroy.next()
    this._destroy.complete()
  }

  private _handleServiceLocationChange(
    selectedServiceLocation: ServiceLocation
  ): void {
    this.selectedServiceLocation.patchValue(selectedServiceLocation, {
      emitEvent: false,
    })
  }

  public isOnPage(urlSegments: string[]): boolean {
    return urlSegments.every((segment) => this._router.url.includes(segment))
  }
}
